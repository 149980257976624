@import "resources/assets/sass/bootstrap-sass/assets/stylesheets/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800&subset=latin,cyrillic);
@import "resources/assets/sass/chartist/chartist";

$font-stack: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$brand-color: #1ab394;
$text-color: #676a6c;
$bg-color: #f3f3f4;

$brand-primary:         #1ab394 !default; // #337ab7
$brand-success:         #1c84c6 !default; // #5cb85c
$brand-info:            #21b9bb !default; // #5bc0de
$brand-warning:         #f7a54a !default; // #f0ad4e
$brand-danger:          #ec4758 !default; // #d9534f

.item-move {
  transition: transform .5s cubic-bezier(.55,0,.1,1);
}

[v-cloak] {
  display: none;
}

div#notifications {
 ::-webkit-scrollbar {
   width: 5px;
 }

 ::-webkit-scrollbar-thumb:vertical {
   margin: 5px;
   background: #e1eaec;
   -webkit-border-radius: 5px;
 } 

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    height: 5px;
    display: block;
  }
}


body {
  font-family: $font-stack;
  background-color: $bg-color;
  color: $text-color;
  overflow-x: hidden;
  font-size: 13px;

  &.modal-opened {
    overflow: hidden;
  }

  a {
    &:hover, &:active, &:visited, &:focus {
      text-decoration: none;
    }
  }

  .block {
    display: block;
  }

  .hide {
    display: none;
  }

  .animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  // LANDING PAGE SPECIFIC
  &#landing-page {
    background: url(../img/landing-page/our-florists.resized.jpg) no-repeat center center fixed;
    background-size: cover;

    > div#content {
      border-top: 1px solid darken($bg-color, 10%);
    }

    footer {
      background:#111;
      padding:50px 0 0 0;
      color:#bbb;
    }

    footer a {
      color:#eee;
    }

    footer h1, footer h2, footer h3, footer h4, footer h5, footer h6{
      color:#eee;
    }

    footer address {
      line-height:1.6em;
    }

    footer h5 {
      font-size: 20px;
      font-weight: 300;
      text-transform: capitalize;
    }

    footer h5 a:hover, footer a:hover {
      text-decoration:none;
    }

    footer ul.link-list {
      padding-left: 0;

      li {
        list-style-type: none;
        line-height: 25px;

        a {
          color: #919191;
          font-weight: 300;

          &:hover {
            color: $brand-color;
          }
        }
      }
    }

    footer ul.link-list li a{
      color:#919191;
    }

    ul.social-network {
      list-style:none;
      margin:0;
    }

    ul.social-network li {
      display:inline;
    }

    #sub-footer{
      background:#0a0a0a;
      text-shadow:none;
      color:#5d5d5d;
      padding-bottom: 50px;
      padding-top:30px;
      margin:20px 0 0 0;
    }

    #sub-footer p{
      margin:0;
      padding:0;
    }

    #sub-footer span{
      color:#c7c7c7;
    }

    .copyright {
      text-align:left;
      font-size:12px;
    }

    #sub-footer ul.social-network {
      float:right;
    }
  }
  // END LANDING PAGE SPECIFIC

  .brand-color {
    color: $brand-color !important;
  }

  ::selection {
    background-color: $brand-color;
    color: whitesmoke;
  }

  ::-moz-selection {
    background-color: $brand-color;
    color: whitesmoke;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-stack;
    font-weight: 600;
    text-transform: uppercase;
    color: #676a6c;
  }

  h1 {
    font-size: 30px;
    font-weight: 700;

		&.margin-30 {
			margin-top: 30px;
			margin-bottom: 20px;
		}
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    font-size: 10px;
  }

  p {
    &.light {
      font-weight: 300;
    }
  }

  strong {
    &.light {
      font-weight: 600;
    }
  }
  
  .clear {
    clear: both;
  }
  
  .text-info {
    color: $brand-info;
  }

  .text-danger {
    color: $brand-danger;
  }

  .text-primary {
    color: $brand-primary;
  }

  .text-success {
    color: $brand-success
  }

  .no-margin {
    margin: 0;
  }

  div.navbar-header {
    padding: 10px
  }

  .btn-round {
    border-radius: 15px;
    padding: 6px 0 !important;
    width: 30px;
    height: 30px
  }

  // Input overrides
  /* INPUTS */
  .input-sm {
    font-size: 12px !important;
    font-weight: normal;
  }

  .inline {
    display: inline-block !important;
  }
  .input-s-sm {
    width: 120px;
  }
  .input-s {
    width: 200px;
  }
  .input-s-lg {
    width: 250px;
  }
  .i-checks {
    padding-left: 0;
  }
  .form-control,
  .single-line {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s !important;
    width: 100%;
    font-size: 14px;
    box-shadow: none;
  }
  .form-control:focus,
  .single-line:focus {
    border-color: #1ab394 !important;
  }
  .has-success .form-control {
    border-color: #1ab394;
  }
  .has-warning .form-control {
    border-color: #f8ac59;
  }
  .has-error .form-control {
    border-color: #ed5565;
  }
  .has-success .control-label {
    color: #1ab394;
  }
  .has-warning .control-label {
    color: #f8ac59;
  }
  .has-error .control-label {
    color: #ed5565;
  }
  .input-group-addon {
    background-color: #fff;
    border: 1px solid #E5E6E7;
    border-radius: 1px;
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 6px 12px;
    text-align: center;
  }

  div#promo {
    margin-top: 250px;

    @media (min-width: $screen-xs-min) {
      margin-top: 0px !important;
    }

    @media (min-width: $screen-sm-min) {
      margin-top: 10px !important;
    }

    @media (min-width: $screen-md-min) {
      margin-top: 150px !important;
    }

    @media (min-width: $screen-lg-min) {
      margin-top: 250px !important;
    }

    //background-color: $brand-color;
    //border: 1px solid darken($brand-color, 1%);
    //box-shadow: 0px 0px 5px lighten(#2b2b2b, 20%);
    padding: 20px;
    border-radius: 10px;
    float: right;

    h1, h2, p {
      color: whitesmoke;
      text-shadow: 1px 1px 3px lighten(#2b2b2b, 10%);

    }

    ul {
      li {
        color: whitesmoke;
        text-shadow: 1px 1px 3px lighten(#2b2b2b, 10%);
        font-weight: 600;
      }
    }

    h1, h2  {
      font-weight: 800;
    }
  }

  div#transperant-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  ul.notifications {
     list-style-type: none;
     position: relative;
     padding: 0px;
     background: white;
     padding: 15px 10px;
     overflow: hidden;

     li.bottom {
       padding: 3px 20px;
       font-size: 12px;
       line-height: 35px;
       text-align: center;
     }

     li.message {
       position: relative;
       padding: 3px 20px;
       font-size: 12px;
       line-height: 35px;
       float: left;
       width: 100%;
       border-radius: 3px;
       text-align: left !important;

       span.admin-thumb {
         border-radius: 15px;
         overflow: hidden;
         display:block;
         margin-right: 5px;
         float: left;
         img {
           max-width: 30px;
           max-height: 30px
         }
       }

       &.unread {
         background: lighten($brand-warning, 32%);
       }

       &:hover {
         cursor: pointer;
         background-color: #f5f5f5;
       }

       &.primary {
         border-left: 10px solid $brand-primary;
       }

       &.success {
         border-left: 10px solid $brand-success;
       }

       &.info {
         border-left: 10px solid $brand-info;
       }

       &.warning {
         border-left: 10px solid $brand-warning;
       }

       &.danger {
         border-left: 10px solid $brand-danger;
       }

       a {
         color: $brand-primary;
         &:hover {
           color: darken($brand-primary, 15%);
           background: none;
         }
       }

       small {
         color: #888888;
         line-height: initial;
         padding-right: 10px;
         margin-right: -10px;
       }
       i.fa {
         font-size: 20px;
       }
     }

     li.divider {
       float: left;
       width: 100%;
       height: 1px;
       margin: 10px 0;
       overflow: hidden;
       background-color: #e5e5e5
     }
   }

  div#notifications {
   .top-button {
     position: fixed;
     left: 135px;
     top: 5px;
     color: white !important;
     background: $brand-color;
     z-index: 2;

     &:hover {
       background: darken($brand-color, 5%)
     }
     i.fa {
       font-size: 17px   
     }
     span.label {
       position: absolute;
       top: 0px;
       right: -8px;
     }
   }
   ul.notifications {
     list-style-type: none;
     padding: 0px;
     position: fixed;
     z-index: 9;
     background: white;
     left: 135px;
     top: 40px;
     width: 500px;
     max-height: 500px;
     border-radius: 5px;
     box-shadow: 0px 0px 5px lighten(#2b2b2b, 60%);
     padding: 15px 10px;
     overflow-y: scroll;

     li.bottom {
       padding: 3px 20px;
       font-size: 12px;
       line-height: 35px;
       text-align: center;
     }

     li.message {
       position: relative;
       padding: 3px 20px;
       font-size: 12px;
       line-height: 35px;
       float: left;
       width: 100%;
       border-radius: 3px;

       &.unread {
         background: lighten($brand-warning, 32%);
       }

       &:hover {
         cursor: pointer;
         background-color: #f5f5f5;
       }

       &.primary {
         border-left: 10px solid $brand-primary;
       }

       &.success {
         border-left: 10px solid $brand-success;
       }

       &.info {
         border-left: 10px solid $brand-info;
       }

       &.warning {
         border-left: 10px solid $brand-warning;
       }

       &.danger {
         border-left: 10px solid $brand-danger;
       }

       a {
         color: $brand-primary;
         &:hover {
           color: darken($brand-primary, 15%);
           background: none;
         }
       }

       small {
         color: #888888;
         line-height: initial;
         padding-right: 10px;
         margin-right: -10px;
       }
       i.fa {
         font-size: 20px;
       }
     }

     li.divider {
       float: left;
       width: 100%;
       height: 1px;
       margin: 10px 0;
       overflow: hidden;
       background-color: #e5e5e5
     }
   }
  }

  div#content {
    margin-top: 50px;
    background-color: $bg-color;
    min-height: 500px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  nav.navbar-default {
    background-color: #2f4050;
    font-size: 13px;

    &.navbar-static-side {
      z-index: 1;
      width: 175px;
      left: 0;
      top: 0;
      bottom: 0;
      position: fixed;
    }

    &.navbar-mobile {
      width: inherit !important;
    }

    &.navbar-static-bottom {
      margin-top: 20px;
      padding: 20px;
      //position: fixed;
      left: 200px;
      bottom: 0;
      right: 0;
      background-color: white !important;
      border: none;
      border-top: 1px solid #e7eaec !important;
      margin-bottom: 0;
    }

    ul.nav {
     li {
       &.active {
         border-left: 4px solid #19aa8d;
         background: #293846;
       }
       a {
         color: #DFE4ED;
         &:focus, &:hover {
           background-color: #293846;
           color: white
         }
       }

       &.nav-header {
         padding: 33px 25px;
         background: url("../img/header-profile.png") no-repeat;
         min-height: 150px;


         .profile-element {
           //text-align: center;
         }

         strong {
           font-weight: 600;
         }

         .muted-text {
           color: darken(#dfe4ed, 25%);
         }
       }
     }
    }

    .dropdown-menu  {
      font-size: 13px;
      background-color: #ffffff;
      border-radius: 3px;

      li.dropdown {
        a {
          line-height: 25px;
          margin: 4px;
          color: rgb(103, 106, 108) !important;
          padding: 3px 20px;
          &:hover {
            color: #262626;
            background-color: #f5f5f5;
          }
        }
      }
    }
  }

  div.container-fluid {
    @media (min-width: $screen-xs-min) {
      margin-left: 70px;
    }
    @media (min-width: $screen-md-min) {
      margin-left: 175px;
    }
    @media (min-width: $screen-lg-min) {
      margin-left: 175px;
    }
    //padding-top: 10px;
    &.content {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .ibox.first-row {
      margin-top: 0;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #e7eaec !important;
  }
  .no-padding {
    padding: 0;
  }

  .white-bg {
    background-color: white;
  }

  nav#top-menu {
    margin-bottom: 0;
  }

  .ibox {
    margin-bottom: 25px;
    box-shadow: 0px 0px 5px lighten(#2b2b2b, 60%);
    
    &.no-shadow {
      box-shadow: none;
    }

    &:hover {
      div.ibox-title > div.ibox-actions {
        display: block;
      }
      
    }

    &#filter {
      margin-bottom: 15px;
      & > .ibox-body {
        padding-bottom: 0;
      }
    }

    .ibox-title {
      background-color: #FFF;
      border-top: 5px solid #e1eaec;
      padding: 14px 15px 7px;
      position: relative;
      //overflow: hidden;

      h3, h5 {
        margin: 10px;
        text-align: center;
      }

      div.ibox-actions {
        position: absolute;
        right: 10px;
        top: 20px;
        display: none;
        &.left {
          right: inherit !important;
          left: 10px;
        }
      }
    }

    .ibox-body {
      background-color: #FFF;
      padding: 15px;
      border-top: 1px solid #e7eaec;
      min-height: 100px;
      text-align: center;
      position: relative;

      span.label-change {
        position: absolute;
        top: 45px;
        right: 20px;
        font-size: 100%;
      }

      &.review {
        min-height: inherit;
        text-align: left;

        p {
          font-weight: 600;
          font-size: 13px
        }
      }

      &.partner {
        min-height: 250px
      }

      .product {
        height: 250px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      h1.thin {
        font-weight: 300;
        font-size: 38px;
        margin: 0;
        display: inline-block;
        text-transform: none;
      }
    }
  }

  .btn-expand {
    width: 100%;
  }

	/*
	 *span.label-change {
	 *  position: absolute;
	 *  top: 50px !important;
	 *  right: 40px !important;
	 *  font-size: 100%;
	 *}
	 */

  #citySelectSpinner {
    display: none;
  }

  div#cities-placeholder {
    position: absolute;
    z-index: 5;
    background-color: white;
    width: 99%;
    left: 2px;
    //padding: 5px;
    box-shadow: 0px 0px 5px lighten(#2b2b2b, 60%);

    ul#cities-list {
      padding: 10px;
      margin-bottom: 0;
      max-height: 300px;
      overflow-y: auto;
      li.city {
        padding: 10px;
        cursor: pointer;
        list-style-type: none;
        //margin-bottom: 10px;
        &:hover {
        background-color: $brand-color;
        color: white;
      }
      }
    }
  }

  // PADDING OVERRIDERS
  .no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
  }

  .xs-gutter > [class*='col-'], .xs-gutter > form > [class*='col-'] {
    &:first-child {
      padding-left: 15px  
    }
    &:last-child {
      padding-right: 15px
    }
    padding-right: 3px;
    padding-left: 3px;
  }

  .sm-gutter > [class*='col-'] {
    padding-right: 6px;
    padding-left: 6px;
  }

  .md-gutter > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }

  // LISTS
  ul.list-unstyled {
    &.left-aligned {
      li {
        text-align: left;
        &.strong {
          font-weight: 600;
        }
      }
    }
    li {
      position: relative;
      &.item {
        background-color: #e1eaec;
        padding: 10px 5px;
        margin-bottom: 5px;
        &:hover {
          background-color: darken(#e1eaec, 5%);

          div.item-controls {
            display: block;
          }
        }

        div.item-controls {
          display: none;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
  }

  // GRID
  .oh {
    overflow: hidden;
  }
  .percents {
    float: left;
    // @todo Media queries
    &.ten {
      width: 10%
    }
    &.twenty {
      width: 20%
    }
    &.thirty {
      width: 30%
    }
    &.fourty {
      width: 40%
    }
    &.fifty {
      width: 50%
    }
    &.sixty {
      width: 60%
    }
    &.seventy {
      width: 70%
    }
    &.eighty {
      width: 80%
    }
    &.ninety {
      width: 90%
    }
    &.hundred {
      min-width: 100%
    }
  }

  .deleted-true {
    color: lighten($text-color, 30%)
  }

  // MISC
  strong, span, em, b, i {
    &.fixed-width {
      width: 75px;
      display: inline-block;
    }
  }

  hr {
    border-top: 1px solid #e7eaec;
  }

  // SELECTIZE
  div.selectize-input {
    text-align: left;
  }

  div.selectize-dropdown {
    text-align: left;
  }

  // TABLES
  table {
    th {
      //font-size: 14px;
    }

    tr {
      &.disabled {
        color: lighten($text-color, 30%)
      }

      &.faded {
        //background-color: darken(#f3f3f3, 10%);
        color: lighten($text-color, 40%);
        
        .label, .badge {
          opacity: 0.4
        }
      }

      &.details {
        //min-height: 100px;
        &:hover {
          background-color: inherit;
        }
        td.details {
          div.row.details {
            min-height: 200px;
          }
        }
      }

      &.heading {
        font-weight: 600;
      }

      td, th {
        span.checkbox {
          margin-top: 0;
          margin-bottom: 0;

          label {
            font-weight: 600;
          }
        }

        &.details {
          hr {
            border-color: #DDD !important;
          }
          h4 {
            &.underlined {
              padding-bottom: 5px;
              border-bottom: 1px solid #DDD;
              text-transform: none;
              line-height: 22px
            }
          }
					h3 {
						&.underlined {
              padding-bottom: 5px;
              border-bottom: 1px solid #DDD;
						}
					}
//          h5 {
//            text-transform: none;
//          }
          li {
            padding: 5px 0;
            strong {
              font-weight: 600;
            }

            div.row div.product-img {
              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  // BUTTONS
  .btn-expand {
    width: 100%;
  }

  .btn-file {
    position: relative;
    overflow: hidden;

    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }

  // BADGES
  .badge {
    &.badge-primary {
      background-color: $brand-primary;
      font-size: 100%
    }
    &.badge-warning {
      background-color: $brand-warning;
      font-size: 100%
    }
    &.badge-info {
      background-color: $brand-info;
      font-size: 100%
    }
    &.badge-danger {
      background-color: $brand-danger;
      font-size: 100%
    }
    &.badge-success {
      background-color: $brand-success;
      font-size: 100%
    }
  }

  // MODALS
  div.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: #f3f3f4;
    opacity: 0.8;
  }

  div.modal-wrapper {
    position: fixed;
    margin: 0 auto;
    top: 10%;
    background-color: white;
    box-shadow: 0px 0px 5px #c4c4c4;
    z-index: 1000;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;


    &.xs {
      width: 20%;
      left: 40%;
    }

    &.sm {
      width: 30%;
      left: 35%;

      @media (min-width: $screen-xs-min) {
        width: 80% !important;
        left: 10% !important;
      }

      @media (min-width: $screen-sm-min) {
        width: 40% !important;
        left: 30% !important;
      }
    }

    &.regular {
      width: 40%;
      left: 30%;

      @media (min-width: $screen-xs-min) {
        width: 100% !important;
        left: 0 !important;
      }

      @media (min-width: $screen-sm-min) {
        width: 60% !important;
        left: 20% !important;
      }
    }

    &.lg {
      width: 60%;
      left: 20%;
    }

    &.xl {
      top: 5%;
      width: 80%;
      height: 90%;
      left: 10%;

      @media (min-width: $screen-xs-min) {
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }

      &.scrollable {
        form {
          height: 100%;

          header {
            height: 12%;
          }
          section.body {
            height: 76%;
            overflow-y: auto;
          }

          footer {
            height: 12%;
          }
        }
      }
    }

    &.xxl {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;

      form {
        height: 100%;

        header {
          height: 10%;
        }
        section.body {
          height: 80%;
          overflow-y: auto;
        }

        footer {
          height: 10%;
        }
      }
    }

    header {
      background-color: #FFF;
      border-top: 5px solid #e1eaec;
      padding: 14px 15px 7px;

      h3, h5 {
        margin: 10px;
        text-align: center;
      }
    }

    section.body {
      padding: 15px 20px 20px 20px;
      border-top: 1px solid #e7eaec;
      min-height: 100px;
      text-align: center;
      height: 100%;
    }

    footer {
      padding: 15px 20px 20px 20px;
      border-top: 1px solid #e7eaec;
      overflow: hidden;
      background-color: darken(#ffffff, 1%);
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      //text-align: center;
    }
  }

  // TOOLTIPS
  .tooltip-inner {
    text-align: left !important;
  }

  // Reviews
  div.rating-container {
    color: #aaa;
    position: relative;
    display: inline-block;
    overflow: hidden;
    float: left;

    div.rating-overlay {
      color: #fde16d;
      position: absolute;
      top: 0;
      left: 0;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
